<template>
  <div>  
      <b-row>
          <b-col>
              <b-card>
                  <rekap-tagihan :items = items @loadDetail = loadDetail></rekap-tagihan>
              </b-card>
          </b-col>
      </b-row>
   
      <detail-tagihan :items = itemsDetail @load = load></detail-tagihan>

  </div>
</template>

<script>
import axios from '@/config/Axios';
import Base from '@/config/Mixins_base';
  import {
      BRow,
      BCol,
      BCard,
      BFormGroup,
      BButton
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import RekapTagihan from './component/RekapTagihan.vue'
  import DetailTagihan from './component/DetailTagihan.vue'
  export default {
      components: {
          BRow,
          BCol,
          BCard,
          vSelect,
          BFormGroup,
          BButton,
          RekapTagihan,DetailTagihan
      },
      mixins:[Base],

      data() {
          return { 
            items : [],
            id_periode_pembayaran : '',
            itemsDetail : []
          }
      },
      mounted(){
        this.load();
      }, methods:{
        async load() {
                const self = this;
                await axios({
                    method: 'PUT',
                    url: '/api/pembayaran/tagihan_mahasiswa/load',
                    data: {
                      
                    },
                    headers: {
                      'Authorization': self.isAuthenticated
                    }
                  })
                  .then(function (response) {
                    self.items = response.data.result;         
                  }).catch(err => {
                    self.pesan = err.message;
                    self.notification('warning', "Error", err.message);
                  });
              },
             
              async loadDetail(val) {
                    const self = this;
                    await axios({
                        method: 'PUT',
                        url: '/api/pembayaran/tagihan_mahasiswa/detail_tagihan',
                        data: {
                            id_periode_pembayaran : val
                        },
                        headers: {
                          'Authorization': self.isAuthenticated
                        }
                      })
                      .then(function (response) {
                        self.itemsDetail = response.data.result; 
              
                      }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                      });
                  },
      }
  }
</script>

<style>

</style>