<template>
    <b-row> 
        <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"> </label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
           
        </b-col>
        <b-col md="6" class="my-1">
            <b-form-group label=" " label-cols-sm="3" label-align-sm="right" label-size="sm"
                label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                            Clear
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(tagihan)="data">
                    <b-row>
                        <b-col md="3">
                            <small>NIM - Nama</small>
                            <h5> {{ data.item.nim }} -  {{ data.item.nm_lengkap }}</h5>
                            <small>Kelas / Tahun Angkatan</small>
                            <h5>{{ data.item.kelas }} - {{ data.item.th_angkatan }}</h5>
                        </b-col> 
                        <b-col md="3">
                            <small>No Invoice</small>
                            <h6 class="text-primary">{{ data.item.no_inv }}</h6>
                            <small>Tgl Create</small>
                            <h6>{{ data.item.date }}</h6>
                        </b-col>
                        <b-col md="4">
                            <small class="text-warning">Nominal Tertagih</small>
                            <h1 class="text-warning" ><strong><sup><small>Rp. </small></sup>{{ Rp(data.item.tertagih) }}</strong></h1>
                        </b-col>
                        <b-col md="2">
                            <small>No HP</small> 
                            <h6>{{ data.item.no_hp }} ({{data.item.dihubung}}x)</h6><a  @click="insert_log(data.item.id)" :href="'https://wa.me/'+data.item.no_wa+'?text=Selamat Pagi, salam sehat, kami dari Keuangan Fakultas Psikologi, menginfokan agar segera meyelesaikan transaksi pembayaran di sistem paytrace fakultas psikologi'" target="_blank">
                                <b-button block variant="warning">  Hubungi via WA <feather-icon icon="MessageSquareIcon" size="20"
                                    />
                                </b-button>
                            </a> 
                        </b-col>
                    </b-row>
                </template>
            </b-table>
        </b-col>
        <b-col cols="6">
            
        </b-col>
        <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
import Base from '@/config/Mixins_base'
import axios from '@/config/Axios'
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
        },
        props:{
            items : {}
        },
        mixins : [Base],
        data() {
            return {
                perPage: 5,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
                fields: [{
                    key: 'tagihan',
                    label: '',
                    thStyle: {
                        display: 'none'
                    }
                }, ],
            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() { 
            // this.totalRows = this.items.length
        },
        emits : ['loadData'],
        methods: {
            async insert_log(id_tagihan) {
                    const self = this;
                    await axios({
                        method: 'POST',
                        url: '/api/pembayaran/tagihan_mahasiswa/insert_log',
                        data: {
                            id_tagihan : id_tagihan 
                        },
                        headers: {
                          'Authorization': self.isAuthenticated
                        }
                      })
                      .then(function (response) {
                        self.rs = response.data.result;
                       self.$emit('loadData')
            
                      }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                      });
                  },
            Rp(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }, 
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>